.works{
    padding: 0 3rem ;
    display: flex;
    height: 90vh;
    margin-top: 4rem;
}
                
/* right side */
.w-right{
    position: relative;
    
}

.w-mainCircle{
    left: 0rem;
    position: relative;
    width: 18rem;
    height: 18rem;
    border-radius: 100%;
    box-shadow: var(--smboxShadow);
    top: 2rem;
    background: white;
}

.w-mainCircle>*{
    position: absolute;
}

.w-mainCircle> :nth-child(1){
    top: -3rem;
    left: 6rem;
}

.w-mainCircle> :nth-child(2){
    left: -3rem;
    top: 5rem;
}

.w-mainCircle> :nth-child(3) {
    left: 6rem;
    top: 5rem;
}
.w-mainCircle> :nth-child(3)>img {
    transform: scale(0.19);
}

.w-mainCircle> :nth-child(4) {
    left: 15rem;
    top: 5rem;
}
.w-mainCircle> :nth-child(4) >img{
    transform: scale(0.19);
}
.w-mainCircle> :nth-child(5) {
    left: 6rem;
    top: 13rem;
}

.w-secCircle{
    width: 6rem;
    height: 6rem;
    position: absolute;
    border-radius: 100%;
    border: 5px solid #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--smboxShadow);
    background: white;
}

.w-secCircle>img{
    transform: scale(0.25);
    border-radius: 100%;
}

.w-bacCircle{
    position: absolute;
    height: 13rem;
    width: 13rem;
    border-radius: 100%;
    z-index: -1;
}

.blueCircle{
    background: #1949B8;
    left: 8rem;
    top: 0;
}

.yellowCircle{
    background: #f5c32c;
    left: 8rem;
    top: 8rem;
}

@media screen and (max-width:480px) {
    .works{
        flex-direction: column;
        height: 55rem;
        padding: 0;
        gap: 5rem;
    }
    .w-right{
        transform: scale(0.7);
        top: -10rem;
    }

}