.services{
    padding: 0 3rem;
    display: flex;
    height: 90vh;
    margin-bottom: 13rem;
    margin-top: 9rem;
    
}

.awesome{
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    
}

.awesome>:nth-child(1){
    color: var(--black);
    font-size: 3.5rem;
    font-weight: bold;
}

.awesome>:nth-child(2){
    color: var(--orange);
    font-size: 3rem;
    font-weight: bold;
}

.awesome>:nth-child(3){
    color: var(--gray);
    font-size: 20px;
    margin-top: 1rem;
    line-height: 25px;
}

.s-button{
    width: 8rem;
    height: 2rem;
    margin-top: 1rem;
}

.cards{
    position: relative;
    flex: 1;
}

.cards>*{
    position: absolute;
}

/* blur */

.s-blur2{
    left: 14rem;
    top: 8rem;
}

.s-blur1{
    top: 13rem;
    left: -18rem;
}

@media  screen and (max-width: 480px) {
    .services{
        margin-top: 0;
        flex-direction: column;
        gap: 5rem;
        height: 66rem;
        padding: 0;
    }
    .cards{
        display: flex;
        flex-direction: column;
        gap: 17rem;
    }

    .cards>*{
        position: static;
    }
}