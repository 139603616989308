.footer{
    display: flex;
    align-items: center;
    margin: -0.5rem -3.5rem;
    position: relative;
    margin-top: -4rem;
    
}

.f-content{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 5rem;
    gap: 4rem;
    color: white;
}

.f-content>span{

}
.f-icons{
    display: flex;
    gap: 2rem;
}

.footer>img{
    height: 25rem;
}



